import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import './subpage.css'

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>

      <Helmet>
        <title>AlphaDex | The Best Amazon Freelancers</title>
        <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best freelancers to help you with your specific amazon store needs." />
      </Helmet>

      <div>
        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred freelancers</h1>
        <table className="subpage-table">
          <thead>
            <tr>
              <th>name</th>
              <th>description</th>
              <th>price</th>
            </tr>
          </thead>
          <tbody>
            {data.allFreelancersJson.edges.map(({ node }, index) => (
              <tr key={index}>
                <td><a href={node.link + "?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{node.name}</a></td>
                <td>{node.description}</td>
                <td>{node.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFreelancersJson {
      edges {
        node {
          id
          name
          link
          description
          price
        }
      }
    }
  }
`